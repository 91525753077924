export const questions = [
    {
        question: "Use the NEAR CLI view method to query the museum contract and report back the number of memes in the museum",
        answers: [
            "just 1",
            "2",
            "3",
            "More than 3"
        ],
        responses: ["More than 3"],
    }
]
