export const backgroundColorBody = '#DBEAFE'
export const backgroundColorDark = '#FFF'
export const backgroundColorLight = '#FFF'
export const backgroundColorChapter = '#071C43'
export const backgroundColorInput = '#1F3255'
export const progressColor = '#22C55E'
export const shadowColor = '#00000040'
export const borderColor = '#272B40'
export const textColor = '#64748B'
export const textColorBlue = '#4338CA'
export const textColorWhite = '#FFF'
export const textColorBlack = '#000'
export const textColorGreen = '#22C55E'
export const textColorMenuItem = '#071C43'
export const textColorMenuItemNumber = '#94A3B8'
export const buttonColor = '#FFF'
export const subTextColor = '#071C43'
export const backgroundTextColor = '#3F4468'
export const bgTextColor = '#000'
export const placeholderColor = '#9090A0'
export const primaryColor = '#2563EB;'
export const secondaryColor = '#00E205'
export const tertiaryColor = '#9013FE'
export const upColor = '#00E205'
export const downColor = '#F90021'
export const headerColor = '#10122399'
export const inactiveColor = '#242649'
export const counterColor = '#6D728F'
export const okColor = '#12650a'

export const near1 = '#FF585D'
export const near2 = '#FFB259'
export const near3 = '#2563EB;'
export const near4 = '#00C1DE'
export const near5 = '#0072CE'
export const near6 = '#F2F2F2'

